import React from 'react'

export default function Testimony() {
  return (
    <div>
        <div className="pb-14 pt-5 md:px-10 px-4">
            <div className="flex-col lg:flex-row flex justify-between md:gap-12 gap-8">
              <div className="w-full m-auto shadow-inner bg-[#F6E1F0] md:px-12 md:py-14 px-6 py-8 text-center md:h-[27rem]">
                <p>
                  "Good Manny's team of experts helped us create a cohesive
                  brand identity that truly represents our values and mission.
                  Their branding and design work was top-notch, and their
                  ability to understand our unique needs and translate that into
                  a visual identity was impressive. We highly recommend their
                  services to anyone looking for a partner who can bring their
                  brand to life."
                </p>
                <div className="pt-2">
                  <h1 className="text-base font-semibold">Rachel Chen</h1>

                  <h1 className="text-base font-semibold">CEO of Green Co</h1>
                </div>
              </div>
              <div className="w-full m-auto shadow-inner bg-[#F6E1F0] md:px-12 md:py-14 px-6 py-8 text-center md:h-[27rem]">
                <p>
                  "Good Manny has been an invaluable partner in helping us
                  develop our business plan. Their team's thorough research and
                  thoughtful analysis helped us better understand our market and
                  competitors, and ultimately craft a strategy that positions us
                  for success. We couldn't have done it without them."
                </p>
                <div className="pt-2">
                  <h1 className="text-base font-semibold">Anna Kamara</h1>
                  <h1 className="text-base font-semibold">
                    Founder of XYZ Startup
                  </h1>
                </div>
              </div>
              <div className="w-full m-auto shadow-inner bg-[#F6E1F0] md:px-12 md:py-14 px-6 py-8 text-center md:h-[27rem]">
                <p>
                  "We had an ambitious vision for our app, and Good Manny helped
                  us bring it to life with their top-notch development and
                  motion design skills. Their attention to detail and commitment
                  to delivering a high-quality product was impressive, and we're
                  thrilled with the final result."
                </p>
                <div className="pt-2">
                  <h1 className="text-base font-semibold"> Sarah Lee</h1>
                  <h1 className="text-base font-semibold">CEO dresx LLC</h1>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
