import React from "react";
import Navbar2 from "../components/Navbar2";
import writing from "../assets/writing3.png";
import Sidebar from "../components/Sidebar";
import Tawk from "../components/Tawk";
import Footer from "../components/Footer";

export default function Writing() {
  const title = "Writing";

  return (
    <div>
      <Navbar2 title={title} />
      <Tawk />

      <section>
        <div className="bg-gradient-to-b from-pink-200 pb-12 ">
          <div className="lg:flex-row flex-col flex lg:gap-10 gap-9 pb-9">
            <div className="lg:w-[53%] w-full">
              <div>
                <img
                  src={writing}
                  className="w-full max-h-[38rem] lg:p-0 pr-12"
                  alt="img"
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full lg:pr-20 px-9 ">
              <div className="lg:pt-28">
                <h1 className="md:text-3xl text-2xl font-semibold pt-2">
                  Captivate, Engage, and Inspire Your Audience
                </h1>

                <p>
                  At Good Manny, we understand the power of words in capturing
                  attention, conveying messages, and building meaningful
                  connections with your audience. Our expert content writing
                  services are designed to help you craft compelling narratives,
                  informative articles, engaging blog posts, and persuasive
                  website content that leaves a lasting impression. Whether you
                  need captivating storytelling or informative and educational
                  pieces, our team of skilled writers is here to deliver content
                  that resonates with your target audience and drives results.
                </p>
              </div>
              <div>
                <h1 className="lg:text-2xl text-xl font-bold pt-4 pb-2">
                  Our Services:
                </h1>
                <ol className="list-decimal flex flex-col gap-y-2">
                  <li>
                    <span className="font-bold">Content Creation: </span>{" "}
                    Whether you need blog posts, articles, website content, or
                    social media updates, our content creation team crafts
                    engaging and informative pieces that capture your brand's
                    essence and engage your target audience.
                  </li>
                  <li>
                    <span className="font-bold">Copywriting: </span>Our
                    copywriters specialize in creating persuasive and
                    captivating copy for advertisements, marketing campaigns,
                    product descriptions, and more. We understand the art of
                    turning words into sales.
                  </li>
                  <li>
                    <span className="font-bold">Creative Writing: </span>If
                    you're looking for imaginative and captivating storytelling,
                    our creative writers can weave narratives that transport
                    readers to new worlds, evoke emotions, and leave a lasting
                    impact.
                  </li>
                  <li>
                    <span className="font-bold">
                      Editing and Proofreading:{" "}
                    </span>
                    Our meticulous editors and proofreaders ensure that your
                    written work is polished, error-free, and ready to shine.
                    Whether it's a manuscript, business proposal, or academic
                    paper, we've got you covered.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="lg:px-20 px-9">
            <div className="flex items-center ">
              <div className="lg:flex-row flex-col flex md:gap-5 gap-14">
                <div className="lg:w-[67%] w-full">
                  <ol className="list-decimal flex flex-col gap-y-2" start="5">
                    <li>
                      <span className="font-bold">Technical Writing: </span>{" "}
                      Communicate complex ideas clearly and concisely with the
                      help of our technical writers. From user manuals and
                      guides to scientific papers, we make intricate concepts
                      understandable for your target audience.
                    </li>
                    <li>
                      <span className="font-bold">Academic Writing: </span>
                      Students and researchers can rely on our academic writers
                      to produce well-researched papers, theses, dissertations,
                      and more. We adhere to academic standards while delivering
                      top-notch content.
                    </li>
                    <li>
                      <span className="font-bold">
                        Resume and Cover Letter Writing:{" "}
                      </span>
                      Present your professional achievements and skills
                      effectively with our resume and cover letter writing
                      services. Our writers know how to make your application
                      stand out in a competitive job market.
                    </li>
                    <li>
                      <span className="font-bold">Translation Services: </span>
                      Break down language barriers with our translation
                      services. Our expert translators ensure that your content
                      retains its meaning, tone, and impact when crossing
                      linguistic boundaries.
                    </li>
                    <li>
                      <span className="font-bold">Ghostwriting: </span>
                      Bring your book idea to life without having to write it
                      yourself. Our ghostwriters collaborate with you to capture
                      your voice and turn your story into a masterpiece.
                      Scriptwriting: From screenplays to video scripts, our
                      scriptwriters craft compelling narratives that captivate
                      audiences in various mediums.
                    </li>
                    <li>
                      <span className="font-bold">Scriptwriting: </span>
                      From screenplays to video scripts, our scriptwriters craft
                      compelling narratives that captivate audiences in various
                      mediums.
                    </li>
                  </ol>
                </div>
                <div className="lg:w-[33%] w-full flex items-center justify-center">
                  <div className="px-4">
                    <div className="px-4">
                      <a
                        href="/contact"
                        className="bg-[#6D0550] text-white md:text-[1.05rem] text-[1rem] rounded-full px-8 py-3"
                      >
                        Get a Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-11">
              <p className="md:text-lg text-sm font-semibold pt-4 lg:px-24">
                No matter the project, big or small, Goodmanny is committed to
                delivering excellence. We take pride in our ability to transform
                ideas into impactful words that resonate with readers, viewers,
                and listeners.
              </p>
              <p className="md:text-lg text-sm font-semibold py-2 lg:px-24">
                Let us be your partner in effective communication. Contact us
                today to discuss how we can bring your words to life!
              </p>
            </div>
          </div>
        </div>
      </section>
      <Sidebar />
      <Footer />
    </div>
  );
}
