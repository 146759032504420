import React from "react";
import "../css/splide.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import splider1 from "../assets/america.jpg";
import splider2 from "../assets/belgium.jpg";
import splider3 from "../assets/canada.jpg";
import { AiFillStar } from "react-icons/ai";

export default function Reviews() {
  const splideOptions = {
    type: "loop",
    perPage: 3,
    perMove: 1,
    breakpoints: {
      640: {
        perPage: 2, // Show one slide per page on mobile screens
      },
      500: {
        perPage: 1, // Show one slide per page on mobile screens
      },
    },
  };
  return (
    <div>
      <section className="px-4 md:px-10">
        <div className="flex md:flex-row flex-col justify-between md:items-center md:pb-16">
          <div className="w-fit pb-[0.5rem]">
            <div className="rounded-md h-1 w-[16rem] bg-gradient-to-r from-[#0C7E7F] to-[#EC68C7]"></div>
            <h1 className="text-2xl text-[#97036e] font-normal pt-6">
              Customers Reviews
            </h1>
          </div>
        </div>
      </section>
      <Splide options={splideOptions} className="pb-7 px-2 flex gap-x-3">
        <SplideSlide className="flex justify-center md:w-1/3">
          <div className="border-[0.6px] p-6 rounded-md w-full">
            <figure className="flex pb-3 gap-x-2">
              <img
                src={splider1}
                alt="img"
                className="rounded-full w-14 h-14"
              />
              <figcaption className="flex flex-col justify-center">
                <p className="font-medium md:text-base text-sm">
                latosshajoseph
                </p>
                <p className="text-xs text-gray-500">United States</p>
              </figcaption>
            </figure>
            <div className="pb-3 text-sm">
              <p>
                “Very professional business plan. I also appreciate the prompt communication.”
              </p>
            </div>
            <div className="flex md:flex-row flex-col justify-between">
              <p className="text-xs text-gray-500">2 months ago</p>
              <div className="flex">
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
              </div>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide className="flex justify-center md:w-1/3">
          <div className="border-[0.6px] p-6 rounded-md w-full">
            <figure className="flex pb-3 gap-x-2">
              <img
                src={splider2}
                alt="img"
                className="rounded-full w-14 h-14"
              />
              <figcaption className="flex flex-col justify-center">
                <p className="font-medium">oussnextdoor</p>
                <p className="text-xs text-gray-500">Belgium</p>
              </figcaption>
            </figure>
            <div className="pb-3 text-sm">
              <p>
                “Good job even in French 👍”
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-xs text-gray-500">1 year ago</p>
              <div className="flex">
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-gray-300" />
              </div>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide className="flex justify-center md:w-1/3">
          <div className="border-[0.6px] p-6 rounded-md w-full">
            <figure className="flex pb-3 gap-x-2">
              <img
                src={splider3}
                alt="img"
                className="rounded-full w-14 h-14"
              />
              <figcaption className="flex flex-col justify-center">
                <p className="font-medium">roymccarthy_</p>
                <p className="text-xs text-gray-500">Canada</p>
              </figcaption>
            </figure>
            <div className="pb-3 text-sm">
              <p>
                “Great and awesome job! Thanks for putting together the best”
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-xs text-gray-500">9 months ago</p>
              <div className="flex">
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
                <AiFillStar className="w-3 h-4 text-yellow-400" />
              </div>
            </div>
          </div>
        </SplideSlide>
      </Splide>
    </div>
  );
}
