import React from "react";
import Navbar from "../components/Navbar";
import time from "../assets/24.gif";
import brand from "../assets/gif/brandGif.gif";
import bp from "../assets/gif/businessGif.gif";
import ui2 from "../assets/gif/uiGif.gif";
import writing2 from "../assets/gif/writingGif.gif";
import video2 from "../assets/gif/motionGif.gif";
import web2 from "../assets/gif/webGif.gif";
import Tawk from "../components/Tawk";
import Footer from "../components/Footer";

export default function Services() {
  return (
    <div>
      <Navbar />
      <Tawk />
      <section>
        <div className="">
          <div className="bg-[#6D0550] lg:px-20 px-8 pt-24 pb-12 text-white">
            <div className="md:flex-row flex-col flex lg:gap-20 md:gap-9 gap-4 pt-4 pb-9 justify-between">
              <div>
                <h1 className="lg:text-4xl text-3xl font-bold max-w-[31rem]">
                  Choose the best digital & branding design{" "}
                </h1>
              </div>
              <div>
                <p className="max-w-[16rem] text-sm">
                  We give our client all our support and guidance
                </p>
              </div>
            </div>
            <div className="flex lg:flex-col md:flex-row flex-col gap-y-12 gap-x-8 md:items-baseline items-center md:py-7">
              <div className="sm:flex-col lg:flex-row md:flex-col flex-col flex lg:gap-12 gap-7 pb-7 md:max-w-none md:w-full sm:w-3/4 w-full max-w-[27rem]">
                <div className="lg:block md:flex sm:flex block lg:w-1/3 w-full">
                  <a href="/branding">
                    <div>
                      <img
                        src={brand}
                        className="w-full lg:max-h-[20rem] md:max-h-[11rem] h-64 rounded-xl"
                        alt="img"
                      />
                    </div>
                    <div className="lg:px-0 px-3">
                      <h1 className="lg:text-2xl text-xl font-bold pt-2">
                        Branding
                      </h1>
                      <p className="lg:text-sm text-xs">
                        Let your branding speak volumes and captivate your
                        audience from the first glance. GoodManny, the ultimate
                        solution for businesses navigating the challenging realm
                        of entrepreneurship,
                      </p>
                    </div>
                  </a>
                </div>
                <div className="lg:block md:flex sm:flex block lg:w-1/3 w-full">
                  <a href="business-plan">
                    <div>
                      <img
                        src={bp}
                        className="w-full lg:max-h-[20rem] md:max-h-[11rem] h-64 rounded-xl"
                        alt="img"
                      />
                    </div>
                    <div className="lg:px-0 px-3">
                      <h1 className="lg:text-2xl text-xl font-bold pt-2">
                        Business Plan
                      </h1>
                      <p className="lg:text-sm text-xs">
                        A business plan serves as your roadmap, guiding you
                        through the challenges and opportunities that lie ahead
                        At Good Manny, we understand the importance of a
                        well-crafted business plan in navigating the complex
                        world of entrepreneurship.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="lg:block md:flex sm:flex block lg:w-1/3 w-full">
                  <a href="ui-ux">
                    <div>
                      <img
                        src={ui2}
                        className="w-full lg:max-h-[20rem] md:max-h-[11rem] h-64 rounded-xl"
                        alt="img"
                      />
                    </div>
                    <div className="lg:px-0 px-3">
                      <h1 className="lg:text-2xl text-xl font-bold pt-2">
                        UIUX
                      </h1>
                      <p className="lg:text-sm text-xs">
                        Whether you need a website, mobile app, or software
                        interface, our talented designers combine creativity,
                        user-centric thinking, and industry best practices to
                        deliver designs that leave a lasting impact.
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="sm:flex-col lg:flex-row md:flex-col flex-col flex lg:gap-12 gap-7 pb-7 md:max-w-none md:w-full sm:w-3/4 w-full max-w-[27rem]">
                <div className="lg:block md:flex sm:flex block lg:w-1/3 w-full">
                  <a href="writing">
                    <div>
                      <img
                        src={writing2}
                        className="w-full lg:max-h-[20rem] md:max-h-[11rem] h-64 rounded-xl"
                        alt="img"
                      />
                    </div>
                    <div className="lg:px-0 px-3">
                      <h1 className="lg:text-2xl text-xl font-bold pt-2">
                        Writing
                      </h1>
                      <p className="lg:text-sm text-xs">
                        Great content goes beyond mere words on a page; it tells
                        a story, sparks emotions, and compels readers to take
                        action. Our content writing services are focused on
                        creating captivating narratives that captivate your
                        audience from the first sentence to the last.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="lg:block md:flex sm:flex block lg:w-1/3 w-full rounded-xl">
                  <a href="motion">
                    <div>
                      <img
                        src={video2}
                        className="w-full lg:max-h-[20rem] md:max-h-[11rem] h-64 rounded-xl"
                        alt="img"
                      />
                    </div>
                    <div className="lg:px-0 px-3">
                      <h1 className="lg:text-2xl text-xl font-bold pt-2">
                        Video/motion editing
                      </h1>
                      <p className="lg:text-sm text-xs">
                        With our video/motion editing services, we go beyond
                        simply stitching together clips. We believe in the art
                        of storytelling, and we'll collaborate closely with you
                        to understand the essence of your content and the
                        message you want to convey.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="lg:block md:flex sm:flex block lg:w-1/3 w-full">
                  <a href="web-and-app-development">
                    <div>
                      <img
                        src={web2}
                        className="w-full lg:max-h-[20rem] md:max-h-[11rem] h-64 rounded-xl"
                        alt="img"
                      />
                    </div>
                    <div className="lg:px-0 px-3">
                      <h1 className="lg:text-2xl text-xl font-bold pt-2">
                        Web and App Development
                      </h1>
                      <p className="lg:text-sm text-xs">
                        At Good Manny, we specialize in creating stunning and
                        functional websites and app that are tailored to meet
                        your business goals and captivate your audience. Our
                        expert website development services combine cutting-edge
                        technology, intuitive design, and a user-centric
                        approach to deliver an online presence that truly
                        represents your brand.
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-white lg:px-20 px-4 md:flex-row flex-col flex lg:gap-32 gap-9 md:py-16 pt-4 pb-9">
              <div className="w-full">
                <h1 className="text-3xl font-bold pb-6">
                  Our service are for startup and existing business
                </h1>
                <p>
                  Whether you're setting sail as a daring startup, embarking on
                  a voyage through uncharted business waters, or an established
                  enterprise seeking to redefine its course, GoodManny's array
                  of services are meticulously crafted to be the compass that
                  guides you. We understand that every business narrative is
                  distinct, and that's why our offerings are a bespoke fusion of
                  insight and innovation, tailored to your individual needs.
                  <br />
                  <br />
                  For the intrepid entrepreneurs taking their first steps, our
                  services act as a springboard, catapulting your vision into
                  reality. We recognize the challenges that mark the early
                  stages of a business, from shaping a robust business plan that
                  garners investor confidence to creating a compelling brand
                  identity that captures hearts. GoodManny is your steadfast
                  companion, providing a roadmap that ensures your journey is
                  paved with strategic decisions and calculated steps.
                  <br />
                  <br />
                  To the seasoned businesses seeking evolution and
                  revitalization, our services offer a breath of fresh air. We
                  understand that the path to success is not linear; it's an
                  evolution that requires continuous adaptation. Our team of
                  experts stands ready to collaborate, delving into the heart of
                  your business to identify untapped potential and devise
                  strategies that ignite growth. From refining your branding to
                  leveraging the power of digital transformation, we're equipped
                  to fuel the next chapter of your success story.
                </p>
              </div>
              <div className="w-full">
                <div className="flex items-end pb-6">
                  <h1 className="text-3xl font-bold pb-8">
                    We are always here for you
                  </h1>
                  <div>
                    <img
                      src={time}
                      className="md:w-[18rem] md:max-h-[14rem] md:h-56 h-44 w-64"
                      alt="img"
                    />
                  </div>
                </div>
                <p>
                  Our unwavering commitment means that we're here for you
                  whenever you need us. As your trusted partners, we stand ready
                  to assist, answer questions, and provide solutions. Your
                  challenges are ours to solve, and your success is our shared
                  victory. At Good Manny, we're more than a service provider;
                  we're your allies in the pursuit of success. Count on us to
                  guide you through the complexities of business.
                </p>
                <div className="md:flex-row flex-col flex md:gap-20 gap-14 justify-between items-end pt-11">
                  <div className="w-full flex">
                    <div>
                      <a
                        href="/contact"
                        className="bg-[#6D0550] text-white md:text-[1.05rem] text-[1rem] rounded-full px-8 py-3"
                      >
                        Get a Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
