import React from "react";
import { AiFillHome } from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import { MdContactPhone } from "react-icons/md";

export default function Sidebar() {
  return (
    <div>
      <div className="fixed right-0 top-[30%]">
        <div className="bg-[#EC68C7] text-white px-2 py-8 gap-12 flex flex-col rounded-l-2xl">
          <a href="/">
            <AiFillHome className="md:w-8 md:h-8 w-7 h-7" />
          </a>
          <a href="/services">
            <TbWorld className="md:w-8 md:h-8 w-7 h-7" />
          </a>
          <a href="/contact">
            <MdContactPhone className="md:w-8 md:h-8 w-7 h-7" />
          </a>
        </div>
      </div>
    </div>
  );
}
