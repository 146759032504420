import React, { useEffect } from "react";

export default function Tawk() {
  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://embed.tawk.to/650a8be10f2b18434fd97eb5/1haoj4rcu";
    const t = document.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s, t);
  }, []);
  return <div></div>;
}