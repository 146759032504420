import React from 'react'

export default function Quote() {
  return (
    <div className="md:flex-row flex-col flex md:gap-20 gap-14 justify-between items-end pt-11">
            <div className="w-full flex justify-center">
              <div className="px-4">
                <a
                  href="/contact"
                  className="bg-[#6D0550] text-white md:text-[1.05rem] text-[1rem] rounded-full px-8 py-3"
                >
                  Get a Quote
                </a>
              </div>
            </div>
          </div>
  )
}
