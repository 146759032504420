import React, { useEffect, useState } from "react";
import "../css/styles.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import business from "../assets/business.png";
import branding from "../assets/branding.png";
import writing from "../assets/writing.png";
import motion from "../assets/motion.png";
import webdev from "../assets/apple.png";
import ux from "../assets/UX.png";
import GoodmannyProducts from "../assets/videos/GoodmannyProducts.mp4";
import laptop from "../assets/laptop.png";
import microsoft from "../assets/microsoft.png";
import fiverr from "../assets/fiverr.png";
import amazon from "../assets/amazon.png";
import shopify from "../assets/shopify.png";
import wordpress from "../assets/wordpress.png";
import team from "../assets/team.png";
import Tawk from "../components/Tawk";
import Testimony from "../components/Testimony";

export default function Home() {
  const [isMuted, setIsMuted] = useState(true);
  const [showBorder1, setShowBorder1] = useState(true);
  const [showBorder2, setShowBorder2] = useState(true);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };
  
  useEffect(() => {
    // After 2s (duration of typing animation), set the border to transparent
    const timer1 = setTimeout(() => {
      setShowBorder1(false);
    }, 2000);
    // After 4s (duration of typing2 animation), set the border to transparent
    const timer2 = setTimeout(() => {
      setShowBorder2(false);
    }, 4000);

    return () => {
      clearTimeout(timer1, timer2);
    };
  }, []);
  return (
    <div>
      <Navbar />
      <Tawk />
      <section>
        <div className="bg1 bg-no-repeat w-full h-full items-center text-white md:pb-20 pt-36 lg:bg-cover md:bg-auto bg-cover pb-48 flex lg:gap-14 md:px-10 px-4 justify-between">
          <div className="lg:w-[35%] md:w-1/2 w-full">
            <div
              className={`typewriter1 max-w-fit ${
                showBorder1 ? "" : "show-border"
              }`}
            >
              <h1 className="lg:text-5xl md:text-3xl font-bold md:leading-tight text-4xl leading-tight pb-2">
                Showcase
              </h1>
            </div>
            <div
              className={`typewriter2 max-w-fit ${
                showBorder2 ? "" : "show-border"
              }`}
            >
              <h1 className="lg:text-5xl md:text-3xl font-bold md:leading-tight text-4xl leading-tight pb-2">
                Your Brand to
              </h1>
            </div>
            <div className="typewriter3 max-w-fit">
              <h1 className="lg:text-5xl md:text-3xl font-bold md:leading-tight text-4xl leading-tight pb-2">
                the World
              </h1>
            </div>
            <p className="pb-12 lg:text-base md:text-[0.8rem] text-base">
              It is no longer news that most businesses don't survive the first
              year because most entrepreneurs need to plan. To kick start or
              expand your business, you need to have an adequate business plan
              ready, brand your business, develop a website to reach more
              audience and implement the latest technology into your business.
            </p>
            <a
              href="/contact"
              className="py-4 px-6 font-semibold rounded-md text-[#6D0550] bg-white"
            >
              Get started
            </a>
          </div>
          <div className="lg:w-[60%] md:w-1/2 w-0">
            <div className="relative lg:h-[26rem] lg:w-full md:h-[18rem] md:w-[22rem] m-auto flex justify-center items-center">
              <img
                src={laptop}
                className="absolute lg:w-[40.25rem] lg:h-[23.5rem] md:w-[20.4rem] md:h-[12.3rem] md:px-[0.5rem] px-[0.4rem] md:pb-8 pb-4"
                alt="img"
              />
              <video
                src={GoodmannyProducts}
                onClick={toggleMute}
                autoPlay
                muted={isMuted}
                className="absolute lg:bottom-[4.6rem] m-auto lg:p-0 pt-[0.67rem] lg:w-[29.7rem] lg:h-[17rem] md:w-[15.6rem] md:h-[14rem] md:px-[0.5rem] px-[0.4rem] md:pb-8 md:top-[1.3rem] pb-4"
                alt="img"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="md:bg-gradient-to-t from-[#ffff00] to-transparent">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className="mysvg lg:mt-[-7rem] md:mt-[-9rem] mt-[-11rem]"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="100%"
          height="100%"
          viewBox="0 0 1600 900"
          preserveAspectRatio="xMidYMax slice"
        >
          <defs>
            <linearGradient id="bg">
              <stop
                offset="0%"
                style={{ stopColor: "rgb(109 5 80 / 85%)" }}
              ></stop>
              <stop
                offset="50%"
                style={{ stopColor: "rgb(139 20 105 / 94%)" }}
              ></stop>
              <stop
                offset="100%"
                style={{ stopColor: "rgb(202 10 148)" }}
              ></stop>
            </linearGradient>
            <path
              id="wave"
              fill="url(#bg)"
              d="M-363.852,502.589c0,0,236.988-41.997,505.475,0
	s371.981,38.998,575.971,0s293.985-39.278,505.474,5.859s493.475,48.368,716.963-4.995v560.106H-363.852V502.589z"
            />
          </defs>
          <g>
            <use xlinkHref="#wave" opacity=".3">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="10s"
                calcMode="spline"
                values="270 230; -334 180; 270 230"
                keyTimes="0; .5; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite"
              />
            </use>
            <use xlinkHref="#wave" opacity=".6">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="8s"
                calcMode="spline"
                values="-270 230;243 220;-270 230"
                keyTimes="0; .6; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite"
              />
            </use>
            <use xlinkHref="#wave" opacty=".9">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="6s"
                calcMode="spline"
                values="0 230;-140 200;0 230"
                keyTimes="0; .4; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite"
              />
            </use>
          </g>
        </svg>
      </section>
      <section>
        <div className=" md:px-20 px-4 pt-20">
          <div className="flex lg:py-0 py-3 lg:justify-end justify-center">
            <marquee className="md:w-[40rem] w-full">
              <div className="flex gap-x-12">
                <img src={microsoft} className="md:h-full h-10" alt="img" />
                <img src={fiverr} className="md:h-full h-10" alt="img" />
                <img src={amazon} className="md:h-full h-10" alt="img" />
                <img src={shopify} className="md:h-full h-10" alt="img" />
                <img src={wordpress} className="md:h-full h-10" alt="img" />
              </div>
            </marquee>
          </div>
          <div className="lg:flex justify-between pt-8">
            <div className="w-full md:text-4xl text-2xl">
              <h1 className="font-semibold lg:w-[80%] pb-2">
                We provide the best branding service that will take your
                Business to the next level.
              </h1>
            </div>
            <p className="w-full headline">
              Are you in search of a consultancy that equips your business with
              the essential tools and strategies to propel it forward? Look no
              further, for you've landed at the perfect destination. Our team
              comprises seasoned experts who excel in various domains, including
              business planning, branding, content and copywriting, web
              development, UI/UX, and motion design.
              <br />
              <br />
              Our mission is to empower business owners and entrepreneurs like
              yourself to not only flourish but also scale your ventures. We
              recognize that the journey to success is rife with challenges,
              which is why we've meticulously curated a suite of services that
              cater to your every need. Whether you're aiming to craft a
              comprehensive business plan that outlines your path to success or
              seeking to establish a distinctive brand identity that resonates
              with your target audience, our team of experts is primed to guide
              you.
              <br />
              <br />
              What sets us apart is our unwavering commitment to delivering
              tailor-made solutions.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="py-14 md:px-10 px-4">
          <div class="lg:grid flex flex-col justify-center grid-cols-3">
            <div class="bg2 m-auto bg-no-repeat bg-cover w-full h-full rounded-l-xl flex pb-2">
              <h1 className="lg:bg-transparent text-white bg-[#a31f7e] px-5 lg:px-0 py-2 lg:py-0 lg:rounded-none rounded-md m-auto lg:text-3xl text-xl lg:pb-0 font-semibold lg:w-12 flex text-center justify-center">
                Our Services
              </h1>
            </div>
            <div class="col-span-2">
              <div class="grid grid-rows-1 grid-flow-col gap-4">
                <div className="md:grid md:grid-cols-3 gap-[0.13rem] lg:text-base md:text-sm text-[0.6rem] text-center">
                  <div>
                    <div class="flex md:grid grid-rows-2 grid-flow-col gap-[0.13rem]">
                      <div className="hover-div relative bg-pink-200 border border-[#EC68C7] flex py-2 md:h-72 h-36 md:px-0 px-2 justify-center flex-col m-auto w-full items-center">
                        <a href="/branding">
                          <div>
                            <img
                              src={branding}
                              className="md:w-16 w-12 pb-2"
                              alt="img"
                            />
                            <p className="top-[1rem]">Branding</p>
                          </div>
                          <div className="hover-content branding">
                            <div className="card">
                              <p className="cardP">
                                <h1 className="cardH sm:text-lg">Branding</h1>
                                Your brand's identity is the heart of your
                                enterprise. We excel at fashioning brand
                                personas that resonate and endure. We understand
                                that your brand's narrative is its compass, and
                                we're here to help you navigate with precision.
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="hover-div relative bg-pink-200 border border-[#EC68C7] flex py-2 md:h-72 h-36 md:px-0 px-2 justify-center flex-col m-auto w-full items-center">
                        <a href="/writing">
                          <div>
                            <img
                              src={writing}
                              className="md:w-16 w-12 pb-2"
                              alt="img"
                            />
                            <p className="top-[1rem]">Writing</p>
                          </div>
                          <div className="hover-content writing">
                            <div className="card">
                              <p className="cardP">
                                <h1 className="cardH sm:text-lg">Writing</h1>
                                Words have the power to inspire action and build
                                bridges. Our writing services harness this power
                                to craft compelling narratives, persuasive
                                proposals, and engaging content that resonates
                                with your audience.
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="flex md:grid grid-rows-2 grid-flow-col gap-[0.13rem]">
                      <div className="hover-div relative bg-pink-200 border border-[#EC68C7] flex py-2 md:h-72 h-36 md:px-0 px-2 justify-center flex-col m-auto w-full items-center">
                        <a href="/business-plan">
                          <div>
                            <img
                              src={business}
                              className="md:w-16 w-12 pb-2 m-auto"
                              alt="img"
                            />
                            <p className="top-[1rem]">Business plan</p>
                          </div>
                          <div className="hover-content business">
                            <div className="card">
                              <p className="cardP">
                                <h1 className="cardH sm:text-lg">
                                  Business plan
                                </h1>
                                A well-structured business plan is the blueprint
                                to your dreams. We specialize in crafting
                                meticulously detailed business plans that
                                outline your journey towards success. With our
                                expertise, you're equipped to steer your
                                enterprise towards its goals.
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="hover-div relative bg-pink-200 border border-[#EC68C7] flex py-2 md:h-72 h-36 md:px-0 px-2 justify-center flex-col m-auto w-full items-center">
                        <a href="/motion">
                          <div>
                            <img
                              src={motion}
                              className="md:w-16 w-12 pb-2 m-auto"
                              alt="img"
                            />
                            <p className="top-[1rem] px-4">
                              Motion Graphics and Video Editing
                            </p>
                          </div>
                          <div className="hover-content business">
                            <div className="card">
                              <p className="cardP">
                                <h1 className="cardH sm:text-lg">
                                  Motion Graphics and Video Editing
                                </h1>
                                Visual storytelling is a potent tool in the
                                modern business arsenal. Our video and motion
                                editing services help you breathe life into your
                                ideas, creating a dynamic canvas to communicate
                                your message effectively.
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="flex md:grid grid-rows-2 grid-flow-col gap-[0.13rem]">
                      <div className="hover-div relative bg-pink-200 border border-[#EC68C7] flex py-2 md:h-72 h-36 md:px-0 px-2 justify-center flex-col m-auto w-full items-center">
                        <a href="/ui-ux">
                          <div>
                            <img
                              src={ux}
                              className="md:w-16 w-12 pb-2"
                              alt="img"
                            />
                            <p className="top-[1rem]">UI/UX</p>
                          </div>
                          <div className="hover-content uiux">
                            <div className="card">
                              <p className="cardP">
                                <h1 className="cardH sm:text-lg">UI/UX</h1>
                                In a digital era, your interface speaks volumes.
                                Our UI/UX design services ensure that your
                                online presence is inviting and intuitive. We
                                understand that user experience is the
                                cornerstone of customer engagement.
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="hover-div relative bg-pink-200 border border-[#EC68C7] flex py-2 md:h-72 h-36 md:px-0 px-2 justify-center flex-col m-auto w-full items-center">
                        <a href="/web-and-app-development">
                          <div>
                            <img
                              src={webdev}
                              className="md:w-16 w-12 pb-2 m-auto"
                              alt="img"
                            />
                            <p className="top-[1rem]">
                              Web and App development
                            </p>
                          </div>
                          <div className="hover-content webapp">
                            <div className="card">
                              <p className="cardP">
                                <h1 className="cardH sm:text-lg">
                                  Web and App development
                                </h1>
                                In a world driven by digital interaction, your
                                web and app presence is a virtual storefront. We
                                specialize in designing and developing digital
                                platforms that seamlessly reflect your brand and
                                foster meaningful connections.
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gradient-to-t from-pink-200">
        <section>
          <div className="pb-14 pt-5 md:px-10 px-4">
            <div className="lg:flex justify-between gap-12">
              <div className="w-full m-auto">
                <h1 className="font-semibold md:text-3xl text-2xl md:pb-1 pb-2">
                  Our Team
                </h1>
                <p className="font-semibold md:text-xl text-lg leading-6 md:pb-2 pb-3">
                  Our team has 10+ years of experience; everyone is a specialist
                  in their field and is always ready to give their best and
                  provide quality and excellent jobs until you are satisfied.
                </p>
                <p>
                  Each member of our team is a torchbearer in their field,
                  possessing a profound understanding of the intricacies that
                  define their craft. Our experts don't merely offer their
                  skills; they invest their passion and dedication, committed to
                  delivering outcomes that exceed your expectations. Their
                  commitment to excellence resonates through every project they
                  undertake.
                  <br />
                  <br />
                  When you engage with us, you're not just gaining access to
                  skill sets; you're forging connections with individuals who
                  share a common ethos: a dedication to your business's success
                  that rivals even your own. With a seamless fusion of
                  creativity and strategy, our team is primed to work
                  collaboratively with you, translating your vision into
                  tangible results.
                </p>
              </div>
              <div className="w-full md:text-3xl text-2xl">
                <img src={team} alt="img" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <Testimony />
        </section>
      </section>
      <Footer />
    </div>
  );
}
