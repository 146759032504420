import React from "react";
import logo2 from "../assets/logo/goodMannyPng1.png";

export default function Navbar2({ title }) {
  return (
    <div>
      <div className="bg-[#6D0550] py-4 px-7 flex justify-between font-semibold lg:text-[0.92rem] md:text-[0.75rem] text-[0.6rem]">
        <div>
          <a href="/">
            <img src={logo2} className="w-[3rem]" alt="img" />
          </a>
        </div>

        <p className="bg-white text-black md:text-[1.05rem] text-[0.7rem] rounded-3xl md:font-semibold font-bold md:px-14 px-6 py-0 md:py-2 text-center items-center flex">
          {title}
        </p>
      </div>
    </div>
  );
}
