import React from "react";
import csn2 from "../assets/csn2.png";
import megride2 from "../assets/megride2.png";

export default function Projects() {
  return (
    <section className="">
      <div className="">
        <div className="rounded-md h-1 w-[16rem] bg-gradient-to-r from-[#0C7E7F] to-[#EC68C7]"></div>
        <h1 className="text-2xl text-[#97036e] font-normal pt-6">
          OTHER PROJECTS
        </h1>
      </div>
      <section className="py-10 px-4 flex lg:flex-row flex-col gap-5 text-white">
      <div className="md:w-1/2">
      <div className="w-full h-full">
            <div class="darken-image w-full">
              <img src={csn2} alt="img" className="rounded-md h-[19rem] w-full object-cover"/>
              <div class="overlay rounded-md p-6 flex md:flex-row flex-col md:justify-between justify-end md:items-end items-start md:gap-0 gap-3">
                <div className=" flex flex-col justify-end">
                  <h1 className="text-2xl font-medium">CSN WebApp</h1>
                  <p className="text-sm max-w-[20rem]">
                    Engage and inspire your audience with expert digital design
                    and development services.
                  </p>
                </div>
                <div>
                  <a
                    href="/csn"
                    className="py-2 px-8 font-medium text-sm rounded-md bg-white text-black"
                  >
                    Explore
                  </a>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="md:w-1/2">
      <div className="w-full h-full">
            <div class="darken-image w-full">
              <img src={megride2} alt="img" className="rounded-md h-[19rem] w-full object-cover"/>
              <div class="overlay rounded-md p-6 flex md:flex-row flex-col md:justify-between justify-end md:items-end items-start md:gap-0 gap-3">
                <div className=" flex flex-col justify-end">
                  <h1 className="text-2xl font-medium">MegRide</h1>
                  <p className="text-sm max-w-[20rem]">
                    Engage and inspire your audience with expert digital design
                    and development services.
                  </p>
                </div>
                <div>
                  <a
                    href="/megride"
                    className="py-2 px-8 font-medium text-sm rounded-md bg-white text-black"
                  >
                    Explore
                  </a>
                </div>
              </div>
            </div>
          </div>
      </div>
      </section>
    </section>
  );
}
