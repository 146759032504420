import React from "react";
import Navbar2 from "../components/Navbar2";
import uid from "../assets/uid.png";
import ui from "../assets/ui.png";
import uiux from "../assets/uiux.png";
import Sidebar from "../components/Sidebar";
import Tawk from "../components/Tawk";
import Footer from "../components/Footer";
import Quote from "../components/Quote";

export default function Uiux() {
  const title = "UIUX";

  return (
    <div>
      <Navbar2 title={title} />
      <Tawk />

      <section>
        <div className="md:px-20 my-container">
          <div className="lg:flex-row flex-col flex md:gap-20 gap-9 pt-4 pb-9">
            <div className="w-full">
              <h1 className="md:text-3xl text-2x pb-2 font-semibold">
                Elevating User Experiences, Engaging Your Audience
              </h1>
              <div>
                <img src={uiux} className="w-full max-h-[30rem]" alt="img" />
              </div>
            </div>
            <div className="w-full">
              <p>
                At Good Manny, we believe that exceptional user experiences are
                at the heart of successful digital products. Our expert UI/UX
                design services are dedicated to creating intuitive and visually
                appealing interfaces that captivate your audience and drive
                engagement. Whether you need a website, mobile app, or software
                interface, our talented designers combine creativity,
                user-centric thinking, and industry best practices to deliver
                designs that leave a lasting impact.
                <br />
                <br />
                A well-designed user interface (UI) is essential for creating
                positive user experiences. Our UI designers specialize in
                crafting visually appealing and intuitive interfaces that
                enhance usability and engagement. We understand the importance
                of simplicity, consistency, and clear navigation. By
                incorporating intuitive design elements and logical information
                architecture, we ensure that users can effortlessly navigate
                through your digital product, accomplishing their goals with
                ease.
                <br />
                <br />
                We believe in a collaborative design process that involves close
                communication with our clients. We understand that your vision
                and input are invaluable in creating designs that truly
                represent your brand. Our iterative approach allows for
                continuous feedback and refinement, ensuring that the final
                product exceeds your expectations. We strive for excellence in
                every detail, from micro interactions to overall design
                consistency, to deliver a polished and delightful user
                experience.
                <br />
                <br />
              </p>
            </div>
          </div>
          <div className="flex text-center  items-center ">
            <div className="md:flex-row flex-col flex md:gap-20 gap-14">
              <div className="md:w-1/2 w-full">
                <a href="/portfolio">
                  <div>
                    <img src={ui} className="w-full max-h-[20rem]" alt="img" />
                  </div>
                  <div className="uiux-box lg:min-h-[15rem] md:min-h-[25rem]">
                    <h1>UI Design</h1>
                    <p className="py-4">
                      A well-designed user interface (UI) is essential for
                      creating positive user experiences. Our UI designers
                      specialize in crafting visually appealing and intuitive
                      interfaces that enhance usability and engagement.
                    </p>
                  </div>
                </a>
              </div>
              <div className="md:w-1/2 w-full">
                <a href="/portfolio">
                  <div>
                    <img src={uid} className="w-full max-h-[20rem]" alt="img" />
                  </div>
                  <div className="uiux-box lg:min-h-[15rem] md:min-h-[25rem]">
                    <h1>UX Design</h1>
                    <p className="py-4">
                      User experience (UX) goes beyond visual aesthetics; it
                      encompasses the overall journey and emotions that users
                      experience while interacting with your product. Our UX
                      designers focus on understanding your target audience,
                      their needs, and pain points. Through user research, wire
                      framing, and prototyping, we create user flows that
                      optimize usability and engagement.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <Quote />
        </div>
      </section>
      <Sidebar />
      <Footer />
    </div>
  );
}
