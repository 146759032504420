import React, { useState } from "react";
import Navbar3 from "../components/Navbar3";
import Tawk from "../components/Tawk";
import Footer from "../components/Footer";
import Modal2 from "../components/Modal2";
import contents from "../components/Contents";

export default function Trends() {
  const [selectedImg, setSelectedImg] = useState(null);
  const [scrolling, setScrolling] = useState(false);


  const contentsPerColumn = Math.ceil(contents.length / 2); // Calculate contents per column

  const openModal = (content) => {
    setSelectedImg(content);
  };

  const closeModal = () => {
    setSelectedImg(null);
  };

  return (
    <div>
      <Modal2
        isOpen={selectedImg !== null}
        img={selectedImg?.img}
        caption={selectedImg?.caption}
        text={selectedImg?.text}
        html={selectedImg?.html}
        onClose={closeModal}
      />

      <Navbar3 scrolling={scrolling} setScrolling={setScrolling} />
      <Tawk />

      <section>
        <div className="">
          <div className="pb-12 text-black">
            <div className="w-full md:h-[25rem] h-[16rem]">
              <div class="darken-image w-full h-full">
                <img
                  src={contents[0].img}
                  alt="img"
                  className="h-full w-full object-cover"
                />
                <div class="overlay h-full md:p-12 p-6 flex md:flex-row flex-col md:justify-between justify-end md:items-end items-start md:gap-0 gap-3">
                  <div className=" flex flex-col justify-end text-white">
                    <h1 className="font-bold md:text-4xl text-2xl">
                      Top Post:
                    </h1>
                    <h1 className="lg:text-2xl md:text-xl font-semibold md:text-center">
                      {contents[0].caption}
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="bg-[#6D0550] text-white text-xl py-2 lg:px-20 px-4 ">
                <h1>RELATED POST</h1>
              </div>
              <div className="flex md:flex-row flex-col md:gap-0 gap-x-4 lg:px-20 px-4 pt-10 text-black">
                <div className="video-page md:flex-row flex-col gap-y-8 gap-x-16 flex text-center items-center justify-center flex-wrap">
                  <div className="md:flex md:flex-row flex-col gap-y-8 gap-x-4">
                    <div className="flex flex-col gap-y-8 gap-x-4 md:w-1/2">
                      {contents.slice(0, contentsPerColumn).map((content) => (
                        <div
                          key={content.id}
                          className="video-thumbnail cursor-pointer md:flex md:gap-x-3"
                          onClick={() => openModal(content)}
                        >
                          <div>
                            <img
                              src={content.img}
                              className="w-full md:min-w-[12rem] lg:max-h-[8rem] rounded-lg md:max-h-[11rem]"
                              alt="img"
                            />
                          </div>
                          <div className="lg:px-0 px-3 md:pt-0 pt-2 flex flex-col justify-start items-start text-left">
                            <p className="text-xs">{content.category}</p>
                            <h1 className="lg:text-xl text-lg font-bold">
                              {content.caption}
                            </h1>
                            <p className="lg:text-sm text-xs md:pb-0 pb-2">
                              {content.blurb}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-y-8 gap-x-4 md:w-1/2">
                      {contents.slice(contentsPerColumn).map((content) => (
                        <div
                          key={content.id}
                          className="video-thumbnail cursor-pointer md:flex md:gap-x-3"
                          onClick={() => openModal(content)}
                        >
                          <div>
                            <img
                              src={content.img}
                              className="w-full md:min-w-[12rem] lg:max-h-[8rem] rounded-lg md:max-h-[11rem]"
                              alt="img"
                            />
                          </div>
                          <div className="lg:px-0 px-3 md:pt-0 pt-2 flex flex-col justify-start items-start text-left">
                            <p className="text-xs">{content.category}</p>
                            <h1 className="lg:text-xl text-lg font-bold">
                              {content.caption}
                            </h1>
                            <p className="lg:text-sm text-xs md:pb-0 pb-2">
                              {content.blurb}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
