import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./public/pages/Home";
import Branding from "./public/pages/Branding";
import Motion from "./public/pages/Motion";
// import Ai from "./public/pages/Ai";
import Plan from "./public/pages/Quote";
import Sample from "./public/pages/Sample";
import Business from "./public/pages/Business";
import AboutUs from "./public/pages/aboutUs";
import Webdev from "./public/pages/Webdev";
import Uiux from "./public/pages/Uiux";
import Services from "./public/pages/Services";
import Trends from "./public/pages/Trends";
import Portfolio from "./public/pages/Portfolio";
import Csn from "./public/pages/Csn";
import Megride from "./public/pages/Megride";
import Writing from "./public/pages/Writing";
import Video from "./public/pages/Video";

function App() {
  return (
    <div className="!font-montserrat">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/branding" element={<Branding />} />
          <Route path="/motion" element={<Motion />} />
          <Route path="/video-gallery" element={<Video />} />
          {/* <Route path="/ai-and-data" element={<Ai />} /> */}
          <Route path="/business-plan" element={<Business />} />
          <Route path="/contact" element={<Plan />} />
          <Route path="/sample" element={<Sample />} />
          <Route path="/web-and-app-development" element={<Webdev />} />
          <Route path="/ui-ux" element={<Uiux />} />
          <Route path="/writing" element={<Writing />} />
          <Route path="/services" element={<Services />} />
          <Route path="/trends" element={<Trends />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/csn" element={<Csn />} />
          <Route path="/megride" element={<Megride />} />
          <Route path="*" element={<Plan />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
