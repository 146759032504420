import React from "react";
import Navbar2 from "../components/Navbar2";
import webdev from "../assets/webdev.png";
import appdev from "../assets/appdev.png";
import webapp from "../assets/gif/webdevGif.gif";
import Sidebar from "../components/Sidebar";
import Tawk from "../components/Tawk";
import Footer from "../components/Footer";
import Quote from "../components/Quote";

export default function Webdev() {
  const title = "Web and App Development";

  return (
    <div>
      <Navbar2 title={title} />
      <Tawk />
      <section>
        <div className="md:px-20 my-container">
          <div className="md:flex-row flex-col flex md:gap-20 gap-9 pt-4 pb-9">
            <div className="w-full">
              <h1 className="text-2xl font-semibold pb-4">
                Get the exact data you need for your application
              </h1>
              <p>
                In today's digital landscape, a well-designed website is crucial
                for attracting and retaining visitors. Our website development
                services focus on creating engaging and user-friendly interfaces
                that provide an exceptional browsing experience. We understand
                that every business is unique, and we take the time to
                understand your brand, target audience, and objectives. Our team
                collaborates closely with you to design and develop websites
                that align with your vision, showcase your products or services,
                and drive conversions.
                <br />
                <br />
                At Good Manny, we specialize in creating stunning and functional
                websites that are tailored to meet your business goals and
                captivate your audience. Our expert website development services
                combine cutting-edge technology, intuitive design, and a
                user-centric approach to deliver an online presence that truly
                represents your brand. Whether you need a responsive website, an
                e-commerce platform, or a custom web application, our team of
                skilled developers is here to bring your vision to life.
                <br />
                <br />
                We understand that every business has unique requirements, and
                we tailor our website and app development services to meet your
                specific needs. Our team takes the time to understand your
                goals, target audience, and desired functionalities. We leverage
                our technical expertise and industry insights to recommend the
                best technologies and frameworks that align with your vision.
                Our agile development process ensures that you are involved at
                every stage, from conceptualization to deployment, to ensure the
                final product exceeds your expectations.
                <br />
              </p>
            </div>
            <div className="w-full">
              <img src={webapp} alt="img" className="rounded-xl" />
            </div>
          </div>
          <div className="flex text-center  items-center ">
            <div className="md:flex-row flex-col flex md:gap-20 gap-14">
              <div>
                <a href="/portfolio">
                  <div>
                    <img
                      src={appdev}
                      className="w-full max-h-[20rem]"
                      alt="img"
                    />
                  </div>
                  <div className="webdev-box lg:min-h-[15rem] md:min-h-[25rem]">
                    <h1 className="text-2xl font-semibold pt-2">
                      App Development
                    </h1>
                    <p className="py-4">
                      In the age of mobile technology, having a powerful and
                      user-friendly mobile app can be a game-changer for your
                      business. At Good Manny, we offer comprehensive app
                      development services that help you harness the potential
                      of mobile platforms and engage your audience on the go.
                      Whether you need an iOS or Android app, our skilled
                      developers have the expertise to create intuitive and
                      feature-rich applications that deliver an exceptional user
                      experience.
                    </p>
                  </div>
                </a>
              </div>
              <div>
                <a href="/portfolio">
                  <div>
                    <img
                      src={webdev}
                      className="w-full max-h-[20rem]"
                      alt="img"
                    />
                  </div>
                  <div className="webdev-box lg:min-h-[15rem] md:min-h-[25rem]">
                    <h1>Web Development</h1>
                    <p className="py-4">
                      For businesses looking to sell products or services
                      online, we specialize in creating robust and secure
                      e-commerce solutions. Our developers leverage
                      industry-leading platforms to build customized online
                      stores that provide a seamless shopping experience for
                      your customers. From intuitive product catalogs to secure
                      payment gateways and inventory management systems, we
                      ensure that every aspect of your e-commerce website is
                      optimized for sales and customer satisfaction.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <Quote />
        </div>
      </section>
      <Sidebar />
      <Footer />
    </div>
  );
}
