import React from "react";
import "../css/splide.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import contents from "./Contents";

export default function Splider() {
  const splideOptions = {
    type: "loop",
    perPage: 3,
    perMove: 1,
    breakpoints: {
      640: {
        perPage: 2, // Show one slide per page on mobile screens
      },
    },
  };
  return (
    <div>
      <h1 className="md:text-3xl text-2xl font-bold pt-4 pb-3 px-6 md:px-12 border border-t-[#7c055b] border-b-0">
        Top Trends
      </h1>
      <Splide options={splideOptions} className="pb-7 px-2 flex gap-5">
        {contents.map((content) => (
          <SplideSlide className="flex justify-center">
            <figure>
              <img src={content.img} alt="img" className="m-auto w-full md:min-w-[11rem] rounded-lg md:h-[13rem] h-[10rem]" />
              <figcaption className="md:text-base text-sm text-center font-semibold">
                <a href="/trends">{content.caption}</a>
              </figcaption>
            </figure>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}
