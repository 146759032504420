import React, { useEffect } from "react";

export default function Scrolling({ scrolling, setScrolling }) {
  let prevScrollpos = window.scrollY;
  // Add a scroll event listener to track the scroll position
  useEffect(() => {
    const handleScroll = () => {
      let currentScrollpos = window.scrollY;

      if (prevScrollpos > currentScrollpos) {
        setScrolling(false);

      } else {
        setScrolling(true);

      }
prevScrollpos = currentScrollpos

    };
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setScrolling]);

  return <div></div>;
}
