import React from "react";
import csn2 from "../assets/csn2.png";
import phone1 from "../assets/Phone1.png";
import process from "../assets/design-process.png";
import design from "../assets/design.png";
import style from "../assets/style-guide.png";
import implement from "../assets/implement.png";
import Projects from "../components/Projects";
import Navbar4 from "../components/Navbar4";

export default function Csn() {
  return (
    <div>
      <Navbar4 />
      <section>
        <div>
          <img
            src={csn2}
            alt="img"
            className="w-full md:h-[23rem] md:object-fill h-[11rem]"
          />
        </div>
      </section>
      <div className="px-0 md:px-14 py-10">
        <section>
          <div className="md:px-0 px-4 pt-4 pb-10">
            <div className="rounded-md h-1 w-[16rem] bg-gradient-to-r from-[#0C7E7F] to-[#EC68C7]"></div>
            <h1 className="text-3xl font-medium pt-6">CSN WebApp</h1>
            <p className="text-sm text-gray-500">
              See the exceptional work we've created for our clients.
            </p>
          </div>
          <div className="md:px-8">
            <div className="bg-[#FAFAFA] py-28 md:px-16 px-4">
              <div className="flex flex-col items-center m-auto justify-center pb-24">
                <p className="py-3 px-10 font-medium rounded-md bg-[#92B0FF]">
                  Overview
                </p>
                <p className="max-w-[28rem] pt-6 text-center">
                  CSN stands for Campus Safety Network which is a flagship
                  initiative of VIP. It’s an ingenuine step aimed at making a
                  smarter and innovative use of technology towards making
                  campuses securer and safer. The foundational build of CSN
                  rests on the primary procedures used in the initial VIP
                  project.
                </p>
              </div>
              <div>
                <img
                  src={phone1}
                  alt="img"
                  className="w-[20rem] h-[8rem] md:object-fill m-auto"
                />
              </div>
              <div className="py-24 flex md:flex-row flex-col justify-center gap-y-12 md:gap-x-16 items-center">
                <div className="flex flex-col gap-y-10">
                  <div>
                    <p className="py-3 px-10 font-medium rounded-md bg-[#FF9891]">
                      The Problem
                    </p>
                    <p className="pt-6 max-w-[26rem]">
                      An average number of student are affected by major
                      security issue due to non-reporting of crimes and lack of
                      public complaints channels.
                    </p>
                  </div>{" "}
                  <div>
                    <p className="py-3 px-10 font-medium rounded-md bg-[#D2D2D2]">
                      Duration
                    </p>
                    <p className="pt-6">10th September - 18th October</p>
                  </div>
                </div>
                <div>
                  <p className="py-3 px-10 font-medium rounded-md bg-[#6BFF8C]">
                    The Goal
                  </p>
                  <p className="pt-6 max-w-[27rem]">
                    CSN App aims to cast a broader outreach. It targets to build
                    a larger network that covers larger spaces, like a whole
                    campus in this case. The goal is to incorporate all the
                    primary and major features of the VIP application into the
                    CSN application along with providing newer services. With
                    CSN, we want to put the best of VIP at serving the security
                    needs of larger educational bodies at a minimal and
                    affordable cost.
                  </p>
                </div>
              </div>
              <div>
                <div className="flex flex-col items-center m-auto justify-center pb-24">
                  <p className="py-3 px-10 font-medium rounded-md bg-[#92B0FF]">
                    Design Process
                  </p>
                  <p className="max-w-[38rem] pt-6 text-center pb-10">
                    In each phase of the design process, focus was made on the
                    user and their needs, which is known as user-centered design
                    (UDC). A number of research and design methods were used to
                    involve users throughout the design process, and this
                    creates a highly usable and accessible product for them.
                  </p>
                  <div>
                    <img
                      src={process}
                      alt="img"
                      className="w-[25rem] md:h-[15rem] md:object-fill h-[11rem]"
                    />
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={design}
                  alt="img"
                  className="md:w-10/12 md:h-[61rem] md:object-fill m-auto"
                />
                <img
                  src={style}
                  alt="img"
                  className="md:w-10/12 md:h-[61rem] md:object-fill m-auto"
                />
                <img
                  src={implement}
                  alt="img"
                  className="md:w-10/12 md:h-full md:object-fill m-auto"
                />
              </div>
            </div>
          </div>
          <div>
            <section>
              <div className="pt-12 md:px-0 px-4">
                <Projects />
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}
