import React, { useState } from "react";
import logo from "../assets/logo/goodMannyPng1.png";
import logobg from "../assets/logobg.png";
import { AiOutlineSearch, AiOutlineMenu } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import Scrolling from "./Scrolling";
export default function Navbar4() {
  // eslint-disable-next-line
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileMenuClose, setMobileMenuClose] = useState(true);
  const [scrolling, setScrolling] = useState(false)

  return (
    <div>
      <Scrolling scrolling={scrolling} setScrolling={setScrolling}/>
      <div className={`navbar ${scrolling ? 'nav-hidden' : 'scrolling bg-[#00000026]'} `}>
      <div className="px-7 h-16 flex justify-between font-semibold lg:text-[0.92rem] md:text-[0.75rem] text-[0.6rem]">
        <div className="w-full md:py-3 py-2 px-2 md:flex justify-between items-center hidden">
          <div>
            <AiOutlineSearch className="lg:w-8 lg:h-[1.3rem] md:w-4 md:h-[0.8rem] w-4 h-3" />
          </div>
          <div className="md:w-1/2 w-[70%]">
            <ul className="flex w-full justify-around">
              <a href="/">
                <li>Home</li>
              </a>
              <a href="/services">
                <li>Services</li>
              </a>
              <a href="/about-us">
                <li>About Us</li>
              </a>
            </ul>
          </div>
        </div>
        <div className="flex lg:w-48 md:w-40 w-20">
          <a href="/">
            <div className="relative w-[7.5rem] flex justify-center">
            <img
              src={logobg}
              className="absolute lg:w-[9rem] lg:h-[9rem] md:w-[4.5rem] md:h-[7rem] w-[5.5rem] h-[6rem] md:px-[0.5rem] px-[0.4rem] md:pb-8 pb-4"
              alt="img"
            />
            <img
              src={logo}
              className="absolute lg:pt-4 pt-[0.67rem] lg:w-[5rem] lg:h-auto md:w-[3.5rem] md:h-auto w-[3.5rem] h-[5rem] md:px-[0.5rem] px-[0.4rem] md:pb-8 pb-4"
              alt="img"
            />
            </div>
          </a>
        </div>
        <div className="w-full md:py-3 py-2 px-2 flex md:justify-between justify-end items-center">
          <div className="md:w-3/5 w-[70%] md:block hidden">
            <ul className="flex justify-around">
              <a href="/trends">
                <li>Trends</li>
              </a>
              <a href="/portfolio">
                <li>Portfolio</li>
              </a>
              <a href="/contact">
                <li>Contact Us</li>
              </a>
            </ul>
          </div>
          <a
            href="/contact"
            className="md:block hidden bg-[#6D0550] text-white lg:text-[0.92rem] md:text-[0.75rem] text-[0.45rem] rounded-full px-4 py-1"
          >
            Get a Quote
          </a>
          <div>
            <AiOutlineMenu
              className="md:hidden block w-9 h-9"
              onClick={(e) => {
                setMobileMenuOpen(true);
                setMobileMenuClose(false);
              }}
            />
            {isMobileMenuClose ? (
              <div>
                <p></p>
              </div>
            ) : (
              <div className="fixed flex items-center justify-end z-50 top-0 left-0 w-full h-screen">
                <div className="bg-gray-100 px-8 pb-5 rounded w-4/5 h-screen">
                  <div className="flex justify-end">
                    <IoClose
                      className="mt-2 text-black h-10 w-9"
                      onClick={(e) => {
                        setMobileMenuOpen(false);
                        setMobileMenuClose(true);
                      }}
                    />
                  </div>

                  <ul className="space-y-3 text-[1.1rem] pt-8">
                    <a href="/">
                      <li>Home</li>
                    </a>
                    <a href="/services">
                      <li>Services</li>
                    </a>
                    <a href="/about-us">
                      <li>About Us</li>
                    </a>
                    <a href="/trends">
                      <li>Trends</li>
                    </a>
                    <a href="/portfolio">
                      <li>Portfolio</li>
                    </a>
                    <a href="/contact">
                      <li>Contact Us</li>
                    </a>
                    <li>
                      <a
                        href="/contact"
                        className="bg-[#6D0550] text-white rounded-full px-4 py-1"
                      >
                        Get a Quote
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <div></div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
