import React, { useState } from "react";
import logo2 from "../assets/logo/goodMannyPng1.png";
import Sidebar from "../components/Sidebar";
import Tawk from "../components/Tawk";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/styles.css";
import Footer from "../components/Footer";

export default function Quote() {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [business, setBusiness] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [clicked, setClicked] = useState(false);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    className: "toast-message",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      name === "" ||
      company === "" ||
      business === "" ||
      email === "" ||
      phone === "" ||
      message === ""
    ) {
      // Handle the case when at least one field is empty
      toast.error("Please fill in all fields.", toastOptions);
    } else {
      setClicked(true);
      const planData = {
        name: name,
        company: company,
        business: business,
        email: email,
        phone: phone,
        message: message,
      };
      try {
        axios
          .post("https://goodmanny-backend.onrender.com/api/mail", planData)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              toast.success("We will get back to you!", toastOptions);
            } else {
              toast.error("please try again later", toastOptions);
            }
          });
      } catch (err) {
        console.error();
      }
    }
  };
  return (
    <div>
      <div className="bg-[#6D0550] py-4 px-7 flex justify-between font-semibold lg:text-[0.92rem] md:text-[0.75rem] text-[0.6rem]">
        <div>
          <a href="/">
            <img src={logo2} className="w-[3rem]" alt="img" />
          </a>
        </div>
      </div>
      <Tawk />
      <section>
        <div className="bg-gradient-to-b from-pink-200 py-12  md:px-24 px-9  ">
          <div className="flex-col flex">
            <div>
              <h1 className="md:text-3xl text-xl font-semibold pb-5">
                BECOME A CLIENT
              </h1>
              <p className="pb-5">
                Join the ranks of successful businesses that have partnered with
                GoodManny to unlock their full potential. With a track record of
                working with over 1000+ clients, we're here to elevate your
                business to new heights. At GoodManny, we understand that every
                business is unique. That's why our services are tailored to meet
                your specific needs and aspirations. From crafting robust
                business plans to designing captivating user experiences, our
                expertise covers a wide spectrum of essential business elements.
              </p>
              <form>
                <div className="flex flex-col justify-start gap-y-4">
                  <input
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Name*"
                    className="outline-none px-6 py-2 md:w-4/5 w-full border-black border"
                  />
                  <input
                    onChange={(e) => setCompany(e.target.value)}
                    type="text"
                    placeholder="Company name*"
                    className="outline-none px-6 py-2 md:w-4/5 w-full border-black border"
                  />
                  <input
                    onChange={(e) => setBusiness(e.target.value)}
                    type="text"
                    placeholder="Business type(startup / Existing)*"
                    className="outline-none px-6 py-2 md:w-4/5 w-full border-black border"
                  />
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email*"
                    className="outline-none px-6 py-2 md:w-4/5 w-full border-black border"
                  />
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    placeholder="Phone number*"
                    className="outline-none px-6 py-2 md:w-4/5 w-full border-black border"
                  />
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    type="text"
                    placeholder="Message*"
                    className="outline-none px-6 py-2 h-[13rem] md:w-4/5 w-full border-black border"
                  ></textarea>
                </div>
                {clicked ? (
                  <button
                    disabled
                    className="disabled:bg-[#3f0d31] bg-[#6D0550] text-white md:text-[1.05rem] text-[1rem] px-16 py-3 mt-6 rounded-full"
                  >
                    Thank you!
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className="bg-[#6D0550] text-white md:text-[1.05rem] text-[1rem] px-16 py-3 mt-6 rounded-full"
                  >
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
          <h1 className="md:text-2xl text-xl font-semibold pt-6">
            Let’s Create Something
            <br />
            AMAZING Together
          </h1>
        </div>
      </section>
      <Sidebar />
      <Footer />
      <ToastContainer />
    </div>
  );
}
