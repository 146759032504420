import React from "react";
import Navbar2 from "../components/Navbar2";
import fullbrand from "../assets/fullbrand.png";
import halfbrand from "../assets/halfbrand.png";
import Sidebar from "../components/Sidebar";
import Tawk from "../components/Tawk";
import Footer from "../components/Footer";

export default function Branding() {
  const title = "Branding services";

  return (
    <div>
      <Navbar2 title={title} />
      <Tawk />
      <section>
        <div className="md:flex-row flex-col flex md:px-20 px-4 md:gap-20 gap-9 py-9 items-center justify-center">
          <div className="md:w-1/2 w-full">
            <h1 className="text-2xl font-semibold">Top Notch Branding</h1>
            <p>
              Let your branding transcend words, becoming an eloquent symphony
              that resonates with your audience from the very first glimpse. At
              GoodManny, we're not just a solution; we're your partner in the
              intricate dance of entrepreneurship. Our mission is clear: to
              equip you with branding services that are nothing short of
              extraordinary.
              <br />
              <br />
              In the dynamic landscape of business, where every step is a
              calculated move towards success, your brand is your most potent
              asset. GoodManny recognizes this, and that's why we're more than
              just a service provider – we're your strategic ally, committed to
              sculpting a brand that stands as a beacon of your values and
              aspirations. Navigating the multifaceted world of entrepreneurship
              can be daunting, laden with uncertainties and challenges. That's
              where we come in.
            </p>
          </div>
          <div className="md:w-1/2 w-full">
            <h1 className="text-2xl font-semibold">Top Notch Branding</h1>
            <p>
              Our team of dedicated experts is well-versed in deciphering these
              challenges, turning them into opportunities for growth. With years
              of industry experience under our belts, we understand the nuances
              of business planning, providing you with insights that transcend
              the conventional.
              <br />
              <br />
              Selecting the right agency to guide your journey is paramount. At
              GoodManny, we don't just offer services; we offer a collaboration
              that's rooted in trust and a shared vision. Our team's passion is
              palpable, and our dedication is unwavering. We're not just vested
              in your project; we're deeply invested in your success.
            </p>
          </div>
        </div>
        <div className="bg-[#6D0550] text-center text-white font-semibold text-xl py-2">
          <h1>Full List of our Branding services</h1>
        </div>
      </section>
      <section>
        <div className="bg-gradient-to-b from-pink-200 pt-16 pb-12  md:px-20 px-4">
          <div className="md:flex-row flex-col flex text-center md:gap-20 gap-14 items-center ">
            <a href="/portfolio">
              <div>
                <div>
                  <img
                    src={fullbrand}
                    className="w-full max-h-[20rem]"
                    alt="img"
                  />
                </div>
                <div className="branding-box lg:min-h-[15rem] md:min-h-[25rem]">
                  <h1>Full Branding</h1>
                  <p className="py-4">
                    Unleash the true potential of your brand with our
                    comprehensive full branding services. Our team of seasoned
                    professionals will meticulously craft a brand identity that
                    resonates with your target audience, reflects your unique
                    values, and sets you apart from the competition. From
                    designing captivating logos and eye-catching visuals to
                    developing a compelling brand story, we ensure every element
                    aligns harmoniously to create a lasting impact. Let your
                    brand speak for itself with our full branding services, and
                    watch as it captivates hearts and minds, leaving an
                    indelible impression.
                  </p>
                </div>
              </div>
            </a>
            <a href="/portfolio">
              <div>
                <div>
                  <img
                    src={halfbrand}
                    className="w-full max-h-[20rem]"
                    alt="img"
                  />
                </div>
                <div className="branding-box lg:min-h-[15rem] md:min-h-[25rem]">
                  <h1>Half Branding</h1>
                  <p className="py-4">
                    For those seeking a more tailored approach, GoodManny offers
                    our specialized half branding services. We understand that
                    every business is unique, and sometimes a partial rebranding
                    is all you need to invigorate your image and attract new
                    opportunities. Whether it's refining your existing brand
                    elements or introducing strategic enhancements, our experts
                    will work closely with you to develop a customized half
                    branding strategy. We'll infuse your brand with renewed
                    energy, ensuring it remains relevant and compelling in the
                    ever-changing market landscape.
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="md:flex-row flex-col flex md:gap-20 gap-14 justify-between items-end pt-11">
            <div className="w-full ">
              <div className="px-4 text-left">
                <h1 className="text-2xl font-semibold py-2">
                  Scope of project:
                </h1>
                <p className="font-semibold">The project will include:</p>
                <ol className="list-disc pl-8">
                  <li>Brand Strategy</li>
                  <li>Moodboard and Direction</li>
                  <li>Full Logo Suite</li>
                  <li>Brand Style Guide and Pattern</li>
                  <li>Social Media Design</li>
                  <li>Email Signature</li>
                  <li>Insta Story Template</li>
                </ol>
              </div>
            </div>
            <div className="w-full flex">
              <div className="px-4">
                <a
                  href="/contact"
                  className="bg-[#6D0550] text-white md:text-[1.05rem] text-[1rem] rounded-full px-8 py-3"
                >
                  Get a Quote
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Sidebar />
      <Footer />
    </div>
  );
}
