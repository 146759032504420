import React from "react";
import all from "../assets/all.png";
import Projects from "../components/Projects";
import Navbar from "../components/Navbar";

export default function Megride() {
  return (
    <div>
      <Navbar />
      <section>
        <div className="bg-[#001A2B] w-full md:h-[30rem] h-[17rem] text-center flex flex-col gap-y-9 justify-center items-center">
          <h1 className="text-white md:text-7xl text-4xl font-semibold">
            MegRide
          </h1>
          <p className="text-[#F26522] md:text-base text-xs">
            LOGO DESIGN PRESENTATION
          </p>
        </div>
        <div className="bg-[#001A2B] w-full h-[22rem] text-center flex flex-col gap-y-8 justify-center items-center">
          <p className="text-[#F26522] font-semibold md:text-2xl text-base">
            Brand Description / Overview
          </p>
          <div className="lg:px-[26rem] md:px-[13rem] px-[2rem] md:text-base text-sm text-white flex flex-col gap-y-3">
            <p>
              MegRide is a Nigerian based Logistics / Transport Startup company
              with services including ride-hailing and package delivery.
            </p>
            <p>
              With the brand development, we decided to go with the brand values
              / attributes – FAST, SAFE, CONVENIENT.
            </p>
            <p>
              Brand identity materials were designed to help launch MegRide, the
              newvoice in the transport industry, reﬂecting the innovative work
              they do.
            </p>
          </div>
          <span className="bg-[#F26522] w-32 h-[0.05rem]"></span>
        </div>
      </section>
      <section>
        <div className="pt-14 pb-8">
          <img src={all} alt="img" className="w-full px-0" />
        </div>
        <div className="px-4 md:px-14 py-10">
          <Projects />
        </div>
      </section>
    </div>
  );
}
