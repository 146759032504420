import React from "react";
import Navbar2 from "../components/Navbar2";
import plan from "../assets/plan.png";
import Tawk from "../components/Tawk";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Testimony from "../components/Testimony";

export default function Business() {
  const title = "Create a Business Plan";

  return (
    <div>
      <Navbar2 title={title} />
      <Tawk />
      <section>
        <div className="bg-gradient-to-b from-pink-200 md:pt-16 pt-9 pb-7  md:px-14 px-9">
          <div className="flex-col flex lg:gap-20 gap-7 pt-4 md:pb-9">
            <div className="flex md:flex-row flex-col gap-10">
              <div>
                <div>
                  <h1 className="text-2xl font-semibold">
                    Crafting a Solid Business Plan: Your Path to Success
                  </h1>
                  <p>
                    At Good Manny, we understand the importance of a
                    well-crafted business plan in navigating the complex world
                    of entrepreneurship. A business plan serves as your roadmap,
                    guiding you through the challenges and opportunities that
                    lie ahead. Our expert team of business plan writers combines
                    industry knowledge, strategic thinking, and a friendly,
                    professional approach to help you create a comprehensive
                    plan that sets the foundation for your business success.
                  </p>
                </div>
                <div>
                  <img
                    src={plan}
                    className="md:w-[80%] w-full h-[16rem] max-h-[20rem]"
                    alt="img"
                  />
                </div>
              </div>
              <div className="flex flex-col lg:gap-10 gap-7">
                <div className="pb-4">
                  <h1 className="text-2xl font-semibold">
                    Understanding the Essence of a Business Plan
                  </h1>
                  <p>
                    A business plan is more than just a document; it's a
                    blueprint for your venture. It outlines your business goals,
                    strategies, and the steps required to achieve them. This
                    detailed roadmap provides a clear vision of your business's
                    purpose, target market, competitive landscape, and financial
                    projections. A well-crafted business plan is not only a tool
                    for internal decision-making but also a vital document when
                    seeking funding, partnerships, or attracting investors. It
                    communicates the viability and potential of your business,
                    showcasing your dedication and commitment to success.
                  </p>
                </div>
                <a
                  href="/contact"
                  className="bg-[#6D0550] text-white md:text-[1.05rem] text-[1rem] rounded-full px-8 py-4 w-fit"
                >
                  Get a Quote
                </a>

                <a
                  href="/sample"
                  className="text-[#6D0550] bg-[#FEC8EF] md:text-[1.05rem] text-[1rem] rounded-full px-8 py-4 w-fit"
                >
                  Check out a Sample
                </a>
              </div>
            </div>
            <div className="flex md:flex-row md:gap-x-3 flex-col">
              <div>
                <h1 className="text-2xl font-semibold">
                  Why you need a business Plan?{" "}
                </h1>
                <ol className="list-decimal">
                  <li>
                    <h1>Guidance and Clarity:</h1>
                    <p>
                      A business plan acts as a guiding light, helping you
                      define your business objectives, mission, and core values.
                      It clarifies your target market, identifies potential
                      challenges, and outlines strategies to overcome them. With
                      a well-defined plan, you gain clarity and focus, making it
                      easier to make informed decisions and stay on track
                      towards your goals.
                    </p>
                  </li>
                  <li>
                    <h1>Attracting Investors and Funding:</h1>
                    <p>
                      When seeking financial support or partnerships, a solid
                      business plan becomes your most valuable asset. Investors
                      and lenders want to see your vision, market analysis, and
                      financial projections. A well-prepared plan demonstrates
                      your business's potential, mitigates risks, and instills
                      confidence in potential stakeholders.
                    </p>
                  </li>
                  <li>
                    <h1>Strategic Decision-Making:</h1>
                    <p>
                      A business plan enables you to make strategic decisions
                      based on comprehensive research and analysis. It helps you
                      identify opportunities, assess market trends, and devise
                      effective marketing and growth strategies. With a clear
                      plan in place, you can navigate challenges proactively and
                      adapt to changing market dynamics
                    </p>
                  </li>
                </ol>
              </div>
              <div>
                <ol start="4" className="list-decimal pt-6">
                  <li>
                    <h1>Operational Efficiency:</h1>
                    <p>
                      A business plan includes an operating plan, detailing the
                      day-to-day operations, organizational structure, and
                      management processes. It provides a roadmap for
                      efficiently running your business, streamlining workflows,
                      and maximizing productivity. Having a well-defined
                      operational plan ensures smooth operations and sets the
                      stage for growth.
                    </p>
                  </li>
                  <li>
                    <h1>Financial Projections and Viability:</h1>
                    <p>
                      Financial projections are a critical component of a
                      business plan. They outline your revenue expectations,
                      expenses, and profitability over a specific timeframe.
                      Accurate financial projections showcase the financial
                      viability of your business, attract potential investors,
                      and aid in making informed financial decisions.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Testimony />
      </section>
      <Sidebar />
      <Footer />
    </div>
  );
}
