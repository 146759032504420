import React from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";

const Modal = ({ isOpen, videoUrl, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="p-20">
      <div className="bg-[#101010c9] fixed flex items-center justify-center z-50 top-0 left-0 w-full h-screen">
        <div className="modal w-full h-full flex justify-center items-center md:p-[17rem] p-[2rem]">
          <div className="modal-content">
            <div className="text-center flex justify-end mr-[-1.6rem] pb-[1rem] cursor-pointer">
              <IoMdCloseCircleOutline
                className="close-button w-8 h-8 text-white"
                onClick={onClose}
              />
            </div>
              <video src={videoUrl} controls/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
