import React from "react";
import Navbar from "../components/Navbar";
import Count from "../components/Count";
import Footer from "../components/Footer";
import Splider from "../components/Splider";

export default function AboutUs() {
  return (
    <div>
      <Navbar />
      <section>
        <div className="bg3 md:h-[24rem] h-[16rem] text-white font-semibold pt-40 pb-24 px-20">
          <h1 className="font-bold md:text-4xl text-3xl">About Us</h1>
        </div>
      </section>
      <section>
        <div className="py-8 md:px-12 px-7 md:max-w-[80%] max-w-[95%]">
          <h1 className="font-bold md:text-3xl text-2xl pb-3">Who we are?</h1>
          <p>
            Good Manny is a brand created for businesses struggling or needing
            help navigating the business world. The brand started as the idea of
            five people who noticed a societal problem and planned to solve it
            with technology and writing. We understand the hurdles people go
            through during business planning, branding, selecting an agency to
            design their website, and many more. We aim to help you solve all
            the stress you may face during your business plan; you can always
            count on us to deliver a better job and give you value for your
            money.
            <br />
            <br />
            Our mantra: we treat everyone's business like it is our business. We
            offer value for money and treat every client like family. You will
            always return for more services because we operate in a friendly
            environment.
          </p>
        </div>
      </section>
      <section>
        <div className="text-white text-center bg-[#6D0550] flex flex-col justify-center items-center px-3 pt-6 pb-16">
          <h2 className="font-semibold md:text-sm text-xs pb-2">
            DEDICATED INDUSTRY EXPERTS WITH PROVEN TRACK RECORDS
          </h2>
          <h1 className="font-bold md:text-3xl text-xl">
            The Team You Want On Your Project
          </h1>
          <div className="font-semibold flex w-full md:justify-between justify-around md:px-12 px-5 pt-8">
            <div className="flex md:flex-row md:justify-around w-full flex-col gap-y-2">
              <div className="text-center">
                <h1 className="md:text-3xl text-xl pb-1">Expert</h1>
                <p className="md:text-sm text-xs">TEAM STRENGTH</p>
              </div>
              <div className="text-center">
                <Count />
                <h1 className="md:text-3xl text-xl pb-1" id="value">
                  50+
                </h1>
                <p className="md:text-sm text-xs">TEAM MEMBERS</p>
              </div>
            </div>
            <div className="flex md:flex-row md:justify-around w-full flex-col gap-y-2">
              <div className="text-center">
                <h1 className="md:text-3xl text-xl pb-1">Top notch</h1>
                <p className="md:text-sm text-xs">DELIVERIES</p>
              </div>
              <div className="text-center">
                <h1 className="md:text-3xl text-xl pb-1">Results</h1>
                <p className="md:text-sm text-xs">DRIVEN APPROACH</p>
              </div>
            </div>
          </div>
        </div>
        <p className="px-12 py-2 bg-gray-300 w-fit text-sm font-semibold text-center m-auto md:mt-[-1.3%] mt-[-4%]">
          TALK TO AN EXPERT
        </p>
      </section>
      <section>
        <div className="pt-5 md:px-12 px-7 md:max-w-[80%] max-w-[95%]">
          <div className="pb-8">
            <h1 className="md:text-3xl text-2xl font-bold pb-2">Capacities</h1>
            <ol className="text-xl list-disc px-6">
              <li>
                <a href="/branding" className="underline">
                  Branding
                </a>
              </li>
              <li>
                <a href="/business-plan" className="underline">
                  Business plan
                </a>
              </li>
              <li>
                <a href="/ui-ux" className="underline">
                  UIUX
                </a>
              </li>
              <li>
                <a href="/writing" className="underline">
                  Writing
                </a>
              </li>
              <li>
                <a href="/motion" className="underline">
                  Video editing and motion graphics
                </a>
              </li>
              <li>
                <a href="/web-and-app-development" className="underline">
                  Web and app development
                </a>
              </li>
            </ol>
          </div>

          <div className="pb-8">
            <h1 className="md:text-3xl text-2xl font-bold pb-2">Clients</h1>
            <p>
              From startups taking their first steps into the business world to
              established enterprises seeking transformative growth, our client
              portfolio is a testament to our commitment to delivering results.
              Our collaborative approach, meticulous attention to detail, and
              passion for innovation have earned us the trust of businesses
              across industries. Our journey is defined by the remarkable
              partnerships we've built with over 1000+ clients. – it's about
              understanding your vision, aligning our strategies, and
              celebrating your successes. We take pride in being a driving force
              behind the growth stories of numerous businesses.
              <br />
              <br />
              Whether it's crafting comprehensive business plans, designing
              captivating user experiences, creating compelling content,
              building strong brands, developing functional websites and apps,
              or producing captivating videos, we've been privileged to
              contribute to the success of businesses both large and small.
            </p>
          </div>
          <div className="pb-8">
            <h1 className="md:text-3xl text-2xl font-bold pb-2">Our Team</h1>
            <h2 className="text-xl font-semibold">
              At the core of our enterprise lies a team of unmatched expertise –
              a collective of specialists who are nothing short of the best in
              their respective domains.
            </h2>
            <p>
              Each member of our team is a torchbearer in their field,
              possessing a profound understanding of the intricacies that define
              their craft. Our experts don't merely offer their skills; they
              invest their passion and dedication, committed to delivering
              outcomes that exceed your expectations. Their commitment to
              excellence resonates through every project they undertake.
              <br />
              <br />
              In an era where diversity and inclusion are paramount, we proudly
              stand as advocates of gender equality. Our team is a true
              reflection of these values – a harmonious blend of talents
              irrespective of gender. This diversity enriches our perspectives,
              fueling innovation and fostering an environment where every idea,
              every approach, is valued for its merit.
              <br />
              <br />
              When you engage with us, you're not just gaining access to skill
              sets; you're forging connections with individuals who share a
              common ethos: a dedication to your business's success that rivals
              even your own. With a seamless fusion of creativity and strategy,
              our team is primed to work collaboratively with you, translating
              your vision into tangible results.
            </p>
          </div>
          <div className="pb-8">
            <h1 className="md:text-3xl text-2xl font-bold pb-2">
              Why choose us?
            </h1>
            <p>
              We have the right hands to handle any project related to business
              planning, branding, design, or growing and scaling the business.
              We are trained to serve you better and deliver a great job;
              working with us won't only guarantee your business, but you will
              be entitled to free consultation when a particular project is
              completed. This means that you are always welcome to visit our
              page and talk to an expert about any problem your business faces,
              and it will all be solved as soon as possible.
              <br />
              <br />
              We recognize that excellence is not a destination but a journey,
              and we're by your side every step of the way. The hallmark of our
              brand is the exceptional quality we consistently deliver. Your
              satisfaction is not just a checkbox; it's a testament to our
              commitment to your success.
            </p>
          </div>
        </div>
      </section>
      <section>
        <Splider />
      </section>
      <Footer />
    </div>
  );
}
