import React from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";

const Modal2 = ({ isOpen, img, caption, text, html, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="p-4">
      <div className="bg-[#101010c9] fixed flex items-center justify-center z-50 top-0 left-0 w-full h-screen">
        <div className="modal w-full h-full flex justify-center items-center p-[2rem]">
          <div className="text-center flex justify-end mr-[-1.6rem] pb-[1rem] cursor-pointer absolute lg:top-[1rem] md:top-[13rem] md:bottom-0 top-0 right-8">
            <IoMdCloseCircleOutline
              className="close-button w-8 h-8 text-white"
              onClick={onClose}
            />
          </div>
          <div className="modal-content w-full h-[-webkit-fill-available] bg-white overflow-y-scroll">
            <img
              src={img}
              alt="img"
              className="w-full h-[13rem] object-cover"
            />
            <div className="px-4 pt-4 pb-11">
              <h2 className="font-bold md:text-2xl font-inherit text-lg pb-2 bg-white border-white">{caption}</h2>
              <p className="text-base pb-8">{text}</p>
              <div className="px-2 myhtml">
                <div dangerouslySetInnerHTML={{__html: html}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal2;
