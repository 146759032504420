import React, { useState } from "react";
import Navbar2 from "../components/Navbar2";
import video from "../assets/video3.png";
import video1 from "../assets/videos/SqiConsultant.mp4";
import video2 from "../assets/videos/endNote.mp4";
import video3 from "../assets/videos/GoodmannyProducts.mp4";
import video4 from "../assets/videos/PicturesShowcase.mp4";
import video5 from "../assets/videos/QonveyApp.mp4";
import Sidebar from "../components/Sidebar";
import Tawk from "../components/Tawk";
import Footer from "../components/Footer";
import Modal from "../components/Modal";

export default function Video() {
  const title = "Motion Graphis & Video Editing";

  const [selectedVideo, setSelectedVideo] = useState(null);

  const videos = [
    { id: 1, url: `${video1}`, caption: "Sqi Consultant Video" },
    { id: 2, url: `${video2}`, caption: "End note Video" },
    { id: 2, url: `${video3}`, caption: "Goodmanny Products Video" },
    { id: 3, url: `${video4}`, caption: "Pictures Showcase Video" },
    { id: 4, url: `${video5}`, caption: "Qonvey App Video" },
    // ... other videos
  ];

  const openModal = (video) => {
    setSelectedVideo(video);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div>
      <Modal
        isOpen={selectedVideo !== null}
        videoUrl={selectedVideo?.url}
        onClose={closeModal}
      />

      <Navbar2 title={title} />
      <Tawk />
      <section>
        <div className="bg-[#CA0A94] md:flex-row flex-col flex justify-center items-center md:px-20 px-9 md:gap-20 gap-9 py-11">
          <div className="flex flex-col gap-y-8 text-white">
            <h1 className="md:text-4xl text-3xl font-semibold">
              Stunning Animated
              <br /> Presentations and Videos.
            </h1>
            <p>
              Get Inspired and pave way for your business in the presence of
              many. Take your business to the next level
            </p>
            <div>
              <a
                href="/contact"
                className="bg-white text-[#CA0A94] font-semibold md:text-[1.05rem] text-[1rem] rounded-full px-8 py-3"
              >
                Get a Quote
              </a>
            </div>
          </div>
          <div>
            <img src={video} alt="img" />
          </div>
        </div>
      </section>
      <section></section>
      <section>
        <div className="bg-gradient-to-b from-pink-200 md:pt-16 pt-8 pb-12  md:px-20 px-9  ">
          <h1 className="md:text-3xl text-2xl font-semibold pb-8">
            Take a Tour!
          </h1>
          <div className="">
            <div className="video-page md:flex-row flex-col gap-y-8 gap-x-16 flex text-center items-center justify-center flex-wrap">
              {videos.map((video) => (
                <div
                  key={video.id}
                  className="video-thumbnail cursor-pointer"
                  onClick={() => openModal(video)}
                >
                  <figure>
                    <video
                      src={video.url}
                      className="lg:w-[30rem] md:w-[18rem] w-[25rem] max-h-[20rem]"
                    />
                    <figcaption className="font-semibold">
                      {video.caption}
                    </figcaption>
                  </figure>
                </div>
              ))}
            </div>
          </div>
          <div className="pt-11 text-center">
            <p className="md:text-base text-sm font-semibold">
              Here is a tip of our designs, We can work on your Ideas to give
              you the best outcome that you want
            </p>
          </div>
        </div>
      </section>
      <Sidebar />
      <Footer />
    </div>
  );
}
