import React from "react";
import logo from "../assets/logo/goodMannyPng1.png";
import { FaFacebook } from "react-icons/fa";
import {
  AiFillLinkedin,
  AiFillInstagram,
  AiOutlineTwitter,
  AiOutlineArrowRight,
} from "react-icons/ai";

export default function Footer() {
  return (
    <div className="md:flex">
      <div className="md:px-12 px-8 py-16 bg-[#6D0550] w-full flex flex-col gap-10">
        <a href="/">
          <img src={logo} alt="img" className="md:w-24 w-16 h-auto" />
        </a>
        <form>
          <p className="text-white pb-2">For more update subscribe</p>
          <div className="flex">
            <input
              type="text"
              className="outline-none w-[70%] px-4 bg-white placeholder:text-black rounded-l-xl"
              placeholder="Enter your Email"
            />

            <button
              className="bg-black text-white md:text-[1.05rem] text-[1rem] px-4 py-2"
            >
              <AiOutlineArrowRight className="w-8 h-7" />
            </button>
          </div>
        </form>
        {/* <div className="text-white underline gap-2 flex md:flex-row flex-col">
          <a href="/">Privacy Policy</a>
          <a href="/">| Terms and condition</a>
          <a href="/">| Cookies Policy</a>
        </div> */}
      </div>
      <div className="md:px-12 px-8 py-16 bg-gray-300 w-full flex flex-col gap-10 justify-center">
        <div className="gap-2 flex md:flex-row flex-col">
          <div>
            <p className="text-black pb-2 font-semibold text-xl">Follow Us</p>
            <div className="flex gap-x-4 text-[#6D0550]">
              <a href="https://twitter.com/GoodManny_" target="_blank" rel="noreferrer">
                <AiOutlineTwitter className="w-12 h-[1.9rem]" />
              </a>
              <a href="https://instagram.com/goodmanny__?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noreferrer">
                <AiFillInstagram className="w-12 h-[1.9rem]" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100063719606620&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                <FaFacebook className="w-8 h-7" />
              </a>
              <a href="https://www.linkedin.com/company/goodmanny" target="_blank" rel="noreferrer">
                <AiFillLinkedin className="w-12 h-[1.9rem]" />
              </a>
            </div>
            <div className="flex pt-12 items-baseline">
              <p className="font-semibold pr-4 text-lg">Contact us: </p>
              <a href="mailto:GoodManny2023@gmail.com" className="underline text-blue-800"> Contact@goodmanny.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
