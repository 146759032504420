import React, { useState } from "react";
import Navbar2 from "../components/Navbar2";
import Sidebar from "../components/Sidebar";
import Tawk from "../components/Tawk";
import Footer from "../components/Footer";

export default function Sample() {
  const title = "Sample Plan";
  const [currentPage, setCurrentPage] = useState(1);

  const pages = [
    {
      heading: "An investor fintech business plan Sample",
      content: (
        <>
          <ol className="list-decimal pl-4">
            <li className="font-bold">Executive Summary</li>
            <p>
              Haraka Software Solutions is a startup company that specializes in
              offering artificial intelligence motion for online gaming. Our
              goal is to revolutionize the world of digital animation by
              providing cutting-edge animation software powered by AI
              technology. We aim to empower artists and developers to bring
              their ideas to life through our innovative and affordable
              solutions. At Haraka Software Solutions, we develop high-quality
              animation software for both 2D and 3D animation. Our products
              offer a seamless workflow and robust features for character
              animation, rigging, motion tracking, and special effects. By
              incorporating artificial intelligence into our software, we enable
              professionals to create realistic and captivating animations with
              unprecedented efficiency and creativity. We take pride in
              delivering software that is accessible to a wide range of users,
              offering them the tools they need to bring their visions to life.
            </p>
            <li className="font-bold pt-5">Industry Overview </li>
            <p>
              <h1 className="pt-3 font-semibold">
                Video game industry in the United States - Statistics & Facts{" "}
              </h1>
              The video game industry in the United States is one of the biggest
              worldwide, spanning businesses of various sizes from single-person
              indie developers to publicly listed, multinational companies that
              employ thousands of people. In 2021, the revenue of the video
              games industry in the United States amounted to an all-time high
              of 85.86 billion U.S. dollars, representing an 11.9 percent growth
              from the previous year. The principal sectors of the video game
              industry are game development and publishing, interactive
              platforms and media, hardware, distribution, retail (digital and
              physical), as well as arcades and video gaming establishments.
            </p>
            <p>
              <h1 className="pt-3 font-semibold">
                Gaming software development & publishing in the United States
              </h1>
              The creation of video games is done by video game developers – in
              2021, U.S. the video game software development industry was
              estimated to be worth 2 billion U.S. dollars. Video game software
              publishers are responsible for publishing and distributing video
              games, providing the link between video game developers and
              commercial distribution companies. In the United States, the
              market size of the video game software publishing industry has
              been increasing over the last decade, reaching over 40.9 billion
              U.S. dollars in 2021.
            </p>
            <p>
              <h1 className="pt-3 font-semibold">
                Video game businesses and employment{" "}
              </h1>
              The video game industry accounts over 143 thousand employees
              across various gaming-related sectors the United States. Of these
              figures, 1,518 establishments were game developers employing
              approximately 40,676 employees. As of 2021, there were an
              estimated 18.5 thousand video game software development businesses
              as well as 1,530 video game publishers in the United States. The
              biggest gaming companies generate huge amounts of direct and
              indirect employment, with Electronic Arts being the biggest
              U.S.-based game developer based on total workforce (however, most
              EA employees work in locations outside the United States).
            </p>
            <li className="font-bold">Executive Summary</li>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
              velit, sed quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
              veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
              vel eum iure reprehenderit qui in ea voluptate velit esse quam
              nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo
              voluptas nulla pariatur?"
            </p>
          </ol>
        </>
      ),
    },
    {
      heading: "A sample farming business plan Sample",
      content: (
        <>
          <ol className="list-decimal pl-4">
            <li className="font-bold">Executive Summary</li>
            <p>
              Juma and Girls Enterprise is a livestock farming business
              operating in the Southern Province of Sierra Leone, specializing
              in the raising and selling of goats, sheep, cows and pigs. The
              business is run by Augustine Juma Fannah and a group of girls,
              with a focus on providing high-quality, sustainably-raised
              livestock to restaurants, supermarkets and traders in the area.
              The company was founded with the goal of improving the livelihoods
              of local communities by providing employment opportunities and
              creating a reliable source of protein for the local population.
              The business has already experienced success, but is seeking
              funding of $50,000 to expand operations and meet growing demand
              for their products.
            </p>
            <li className="font-bold pt-5">Industry Overview </li>
            <p>
              <h1 className="pt-3">MEAT - SIERRA LEONE</h1>
              <ol className="list-disc">
                <li>
                  Revenue in the Meat segment amounts to US$468.80m in 2023. The
                  market is expected to grow annually by 7.21% (CAGR 2023-2027).
                </li>
                <li>
                  In global comparison, most revenue is generated in the United
                  States (US$165.30bn in 2023).
                </li>
                <li>
                  In relation to total population figures, per person revenues
                  of US$55.33 are generated in 2023.
                </li>
                <li>
                  In the Meat segment, volume is expected to amount to 44.3mkg
                  by 2027. The Meat segment is expected to show a volume growth
                  of -1.2% in 2024.
                </li>
                <li>
                  The average volume per person in the Meat segment is expected
                  to amount to 5.8kg in 2023.
                </li>
                <li>
                  Sierra Leonean Meat Consumption is set to reach 63.32 Thousand
                  Metric Tons by 2026 from 65 Thousand Metric Tons in 2021,
                  falling by 0.4% year on year average rate. Since 2017, Sierra
                  Leonean demand fell by 1% year on year. The country ranked
                  number 133 in 2021, Mauritius overtook with 65 Thousand Metric
                  Tons. The United States, Brazil and Russia respectively were
                  numbers 2, 3 and 4 in this ranking.
                </li>
                <li>
                  Sierra Leonean Meat Production is set to reach 50,780 Metric
                  Tons by 2026 from 46,700 Metric Tons in 2021, growing 1.4%
                  year on year CAGR. Since 1966, Sierra Leonean supply rose 1.7%
                  year on year. The country ranked number 140 in 2021, Mauritius
                  came ahead with 46,700 Metric Tons. The United States Virgin
                  Islands, Brazil and Russia respectively came in numbers 2, 3
                  and 4 in this ranking.
                </li>
              </ol>
            </p>
          </ol>
        </>
      ),
    },
    // Add more pages as needed
  ];

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div>
      <Navbar2 title={title} />
      <Tawk />
      <section>
        <div className="bg-gradient-to-b from-pink-200 py-12  md:px-24 px-9  ">
          <div className="flex-col flex pt-4">
            <div className="pb-[2rem]">
              <h1 className="text-2xl font-semibold pb-6">
                {pages[currentPage - 1].heading}
              </h1>

              <div className="h-[40rem] overflow-y-auto bg-gray-300 border border-black p-4">
                {pages[currentPage - 1].content}
              </div>
            </div>
            <div className="flex items-center">
              {currentPage > 1 && (
                <button
                  onClick={handlePrevPage}
                  className="bg-[#6D0550] text-white md:text-[1.05rem] text-[1rem] w-fit rounded-full px-8 py-3"
                >
                  Previous
                </button>
              )}
              {currentPage < pages.length && (
                <button
                  onClick={handleNextPage}
                  className="bg-[#6D0550] text-white md:text-[1.05rem] text-[1rem] w-fit rounded-full px-8 py-3"
                >
                  Next
                </button>
              )}

              {/* <div className="md:flex-row flex-col flex md:gap-20 gap-14 justify-between items-end pt-11"> */}
              {/* <div className="w-full flex justify-end"> */}
              <div className="px-4">
                <a
                  href="/contact"
                  className="bg-[#6D0550] text-white md:text-[1.05rem] text-[1rem] rounded-full px-8 py-3"
                >
                  Get a Quote
                </a>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </section>
      <Sidebar />
      <Footer />
    </div>
  );
}
