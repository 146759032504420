import React from "react";
import Navbar2 from "../components/Navbar2";
import motionGraphics from "../assets/motionGraphics.png";
import video from "../assets/video.png";
import Sidebar from "../components/Sidebar";
import Tawk from "../components/Tawk";
import Footer from "../components/Footer";
import Quote from "../components/Quote";

export default function Motion() {
  const title = "Motion Graphics & Video Editing";

  return (
    <div>
      <Navbar2 title={title} />
      <Tawk />
      <section className="">
        <div className="md:px-20 px-9 md:gap-20 gap-9 py-20 bg-black">
          <div className="lg:w-[40%] text-white">
            <h1 className="md:text-4xl text-2xl font-semibold pb-2">
              Short, Precise, Dynamic,Visually-attractive
            </h1>
            <p>
              At GoodManny, we believe that motion graphics have the power to
              transform ordinary videos into extraordinary works of art. Our
              team of talented designers stays up-to-date with the latest
              trends, techniques, and software to ensure that your motion
              graphics are cutting-edge and visually impactful. We combine our
              creative prowess with a deep understanding of your brand identity,
              ensuring that every animation and graphic element aligns
              seamlessly with your overall visual aesthetic. Get ready to
              unleash your creativity and amplify the impact of your videos with
              our exceptional motion graphics services.
            </p>
          </div>
        </div>
        <div className="bg-[#6D0550] text-center text-white font-semibold text-xl py-2">
          <h1>Full List of our Branding services</h1>
        </div>
      </section>
      <section>
        <div className="bg-gradient-to-b from-pink-200 pt-16 pb-12  md:px-20 px-9  ">
          <div className="md:flex-row flex-col flex md:gap-20 gap-9 pt-4 pb-9 justify-center items-center">
            <div className="md:w-1/2 w-full">
              <h1 className="text-2xl font-semibold">
                Crafting Unforgettable Stories
              </h1>
              <p>
                We go beyond simply stitching together clips. We believe in the
                art of storytelling, and we'll collaborate closely with you to
                understand the essence of your content and the message you want
                to convey. Our skilled editors will carefully curate and arrange
                your footage, ensuring seamless transitions, captivating
                visuals, and a narrative that resonates with your audience. We
                pay attention to every detail, from color correction and audio
                enhancement to adding special effects and music, to create a
                truly immersive and unforgettable viewing experience.
              </p>
            </div>
            <div className="md:w-1/2 w-full">
              <h1 className="text-2xl font-semibold">
                Professional Touch, Personalized Approach
              </h1>
              <p>
                At GoodManny, we pride ourselves on our professional touch and
                personalized approach. We recognize that every video has its own
                unique requirements and objectives. That's why we take the time
                to understand your vision and tailor our editing techniques to
                suit your needs. Whether you need a promotional video, a
                corporate presentation, a social media clip, or a personal
                project, we have the expertise to deliver exceptional results
                that align with your goals. We pay attention to every detail,
                from color correction and audio enhancement to adding special
                effects and music, to create a truly immersive and unforgettable
                viewing experience.
              </p>
            </div>
          </div>
          <div className="flex text-center  items-center ">
            <div className="md:flex-row flex-col flex md:gap-20 gap-14">
              <div>
                <a href="/video-gallery">
                  <div>
                    <img
                      src={video}
                      className="w-full md:h-[20rem] max-h-[20rem]"
                      alt="img"
                    />
                  </div>
                  <div className="motion-box lg:min-h-[15rem] md:min-h-[25rem]">
                    <h1>Video Editing</h1>
                    <p className="py-4">
                      At GoodManny, we understand the power of visuals in
                      conveying your message and capturing your audience's
                      attention. Our expert video editing services bring your
                      raw footage to life, transforming ordinary moments into
                      extraordinary masterpieces. Whether you're a content
                      creator, a business owner, or an aspiring filmmaker, our
                      talented team of editors will work their magic to create
                      compelling videos that engage, inspire, and leave a
                      lasting impact.
                    </p>
                  </div>
                </a>
              </div>
              <div>
                <a href="/video-gallery">
                  <div>
                    <img
                      src={motionGraphics}
                      className="w-full md:h-[20rem] max-h-[20rem]"
                      alt="img"
                    />
                  </div>
                  <div className="motion-box lg:min-h-[15rem] md:min-h-[25rem]">
                    <h1>Motion graphics</h1>
                    <p className="py-4">
                      Our motion graphics team understands the power of visual
                      storytelling. We'll collaborate with you to understand
                      your brand, your message, and your target audience.
                      Whether you need animated logos, text overlays, dynamic
                      transitions, or complex visual effects, we'll seamlessly
                      integrate motion graphics into your videos to enhance the
                      narrative, engage viewers, and create a memorable visual
                      experience.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <Quote />
        </div>
      </section>
      <Sidebar />
      <Footer />
    </div>
  );
}
